






import Vue from 'vue'

export default Vue.extend({
  name: 'online-indicator',
  props: {
    status: String
  },
  data: () => ({
  }),
  computed: {
    onlineObject: function () {
      if (this.status === 'connected') {
        return ['online', 'font-weight-bold']
      }
      return ['offline', 'font-weight-bold']
    },
    online_status: function () {
      if (this.status === 'connected') {
        return 'Online'
      }
      return 'Offline'
    }
  }
})
