






import Vue from 'vue'

export default Vue.extend({
  name: 'status-chip',
  props: {
    status: String
  },
  data: () => ({
  }),
  computed: {
    statusColor: function () {
      if (this.status === 'monitoring') {
        return 'blue'
      }
      return 'red'
    },
    statusText: function () {
      if (this.status === 'monitoring') {
        return 'Monitoring'
      }
      return 'Alerted'
    }
  }
})
