



































































import Vue from 'vue'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, firestore } from '../../main'

export default Vue.extend({
  name: 'update-device-dialog',
  props: ['deviceID'],
  data: () => ({
    configsnackbar: false,
    dialog: false,
    loading: false,
    temperatureTarget: null,
    temperatureWarning: null,
    temperatureAlert: null,
    periodSelect: [60, 120],
    period: null
  }),
  created () {
    this.getUserSettings()
  },
  methods: {
    async getUserSettings () {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }
      this.loading = true

      const token = await auth.currentUser?.getIdToken()
      if (user === undefined || user === null) {
        return
      }

      // console.log(token)

      const deviceref = doc(firestore, 'users', user, 'devices', this.deviceID)
      const deviceSnapshot = await getDoc(deviceref)

      if (deviceSnapshot.exists()) {
        this.temperatureTarget = deviceSnapshot.get('config.target_temperature')
        this.temperatureWarning = deviceSnapshot.get('config.warning_temperature')
        this.temperatureAlert = deviceSnapshot.get('config.alert_temperature')
        this.period = deviceSnapshot.get('config.telemetry_period')
      }

      this.loading = false
    },
    async updateDeviceConfig () {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }
      const token = await auth.currentUser?.getIdToken()
      if (user === undefined || user === null) {
        return
      }

      this.loading = true

      const url = `https://dashboard-backend-2jhuvii37a-uc.a.run.app/api/v1/device/${this.deviceID}/config`
      const data = {
        temperature_alert: Number(this.temperatureAlert),
        temperature_warning: Number(this.temperatureWarning),
        temperature_target: Number(this.temperatureTarget),
        telemetry_period: Number(this.period)
      }
      const params = {
        body: JSON.stringify(data),
        headers: {
          authorization: token!
        },
        method: 'POST'
      }

      await fetch(url, params)

      this.loading = false
      this.dialog = false
      this.configsnackbar = true
    },
    getSelect (a: any) {
      this.period = a
    }
  }
})
