


















































import Vue from 'vue'
import { collection, doc, getDoc, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { auth, firestore, functions } from '../main'
import OnlineIndicator from './OnlineIndicator.vue'
import { httpsCallable } from 'firebase/functions'
import UpdateDeviceDialog from './dialogs/UpdateDeviceDialog.vue'
import StatusChip from './chips/StatusChip.vue'

export default Vue.extend({
  name: 'device-table',
  components: {
    OnlineIndicator,
    UpdateDeviceDialog,
    StatusChip
  },
  data: () => ({
    devices: [],
    loaded: false
  }),
  created () {
    this.getDevices(this)
  },
  methods: {
    getDevices: async (context: any) => {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }

      const token = await auth.currentUser?.getIdToken()
      if (user === undefined || user === null) {
        return
      }

      console.log(token)

      const devicesref = collection(firestore, 'users', user, 'devices')
      const devicesQuerySnapshot = await getDocs(devicesref)

      devicesQuerySnapshot.forEach(async (device) => {
        const incomingDevice = {
          device: '',
          device_id: '',
          connection_status: '',
          temperature: 'unknown',
          temperature_timestamp: '',
          firmware: '',
          monitoring_state: ''
        }
        incomingDevice.device = (device.get('nickname') ? device.get('nickname') : device.get('device_id'))
        incomingDevice.device_id = device.get('device_id')
        incomingDevice.connection_status = device.get('connection_status')
        incomingDevice.monitoring_state = device.get('state.state')

        if (device.get('firmware_version') === undefined) {
          if (device.get('state.firmware_version') === undefined) {
            incomingDevice.firmware = 'unknown'
          } else {
            incomingDevice.firmware = device.get('state.firmware_version')
            await context.checkForUpdate(device.get('state.firmware_version'))
          }
        } else {
          incomingDevice.firmware = device.get('firmware_version')
        }
        // if (device.get('state.firmware_version') === undefined) {
        //   incomingDevice.firmware = 'unknown'
        // } else {
        //   incomingDevice.firmware = device.get('state.firmware_version')
        //   await context.checkForUpdate(device.get('state.firmware_version'))
        // }

        if (device.get('telemetry.temperature') === undefined) {
          const telemetryref = collection(firestore, device.ref.path, 'telemetry_timeline')
          const q = query(telemetryref, orderBy('timestamp', 'desc'), limit(1))
          const telemetrysnapshot = await getDocs(q)
          telemetrysnapshot.forEach((telemetry) => {
            incomingDevice.temperature = telemetry.get('temperature')
            incomingDevice.temperature_timestamp = new Date(telemetry.get('timestamp') * 1000).toLocaleTimeString().toString() + ' ' + new Date(telemetry.get('timestamp') * 1000).toLocaleDateString().toString()
          })
        } else {
          incomingDevice.temperature = device.get('telemetry.temperature')
          incomingDevice.temperature_timestamp = new Date(device.get('last_seen') * 1000).toLocaleTimeString().toString() + ' ' + new Date(device.get('last_seen') * 1000).toLocaleDateString().toString()
        }

        context.devices.push(incomingDevice)
      })

      context.loaded = true
    },
    navigateToDevicePage: function (deviceID: string) {
      console.log(deviceID)
      this.$router.push(`device/${deviceID}`)
    },
    openConfigDialog: async function (deviceID: string) {
      const updateconfig = httpsCallable(functions, 'device-updatedeviceconfig')
      await updateconfig({
        deviceID: deviceID
      })
    },
    checkForUpdate: async function (firmwareVersion: string) {
      const firmwareRef = doc(firestore, 'ota', 'metadata')
      const firmwareSnapshot = await getDoc(firmwareRef)
      if (firmwareSnapshot.exists()) {
        console.log(firmwareSnapshot.data())
        if (firmwareSnapshot.get('latest') !== firmwareVersion) {
          console.log('FOUND A NEW VERSION')
        }
      } else {
        console.log('No such document!')
      }
    }
  }

})
