import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { initializeFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { getMessaging } from 'firebase/messaging'

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const firebaseConfig = {
  apiKey: 'AIzaSyDas-Ni0NAd0lT7ViJ5CrDB89zNVUTz_SM',
  authDomain: 'freezer-monitor-dev-e7d4c.firebaseapp.com',
  projectId: 'freezer-monitor-dev-e7d4c',
  storageBucket: 'freezer-monitor-dev-e7d4c.appspot.com',
  messagingSenderId: '481474188273',
  appId: '1:481474188273:web:3e2e3eb33f0e653de6aa50',
  measurementId: 'G-7JS46QMCKJ'
}

// Initialize Firebase
const fapp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(fapp)
export const auth = getAuth(fapp)
export const firestore = initializeFirestore(fapp, {})
export const functions = getFunctions(fapp)
export const messaging = getMessaging(fapp)

Vue.prototype.$messaging = messaging

// TODO : Look further into Firebase persistance
let app:Vue
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
