























import Vue from 'vue'
import DeviceTable from '../components/DeviceTable.vue'
import NewDeviceDialog from '../components/dialogs/NewDeviceDialog.vue'

export default Vue.extend({
  name: 'dashboard-view',
  components: {
    DeviceTable,
    NewDeviceDialog
  },
  data: () => ({
    deviceKey: 0
  })
})
