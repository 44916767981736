

























































































import Vue from 'vue'
import { auth, functions } from '../../main'

export default Vue.extend({
  name: 'new-device-dialog',
  data: () => ({
    deviceID: '',
    nickname: '',
    dialog: false,
    loading: false,
    errorsnackbar: false,
    snackbar: false,
    temperatureTarget: null,
    temperatureWarning: null,
    temperatureAlert: null,
    periodSelect: [60, 120],
    period: null
  }),
  methods: {
    async addDeviceToAccount () {
      const user = auth.currentUser?.email
      if (user === undefined || user === null) {
        return
      }

      const token = await auth.currentUser?.getIdToken()
      if (user === undefined || user === null) {
        return
      }

      //   console.log(token)

      this.loading = true

      const url = 'https://dashboard-backend-2jhuvii37a-uc.a.run.app/api/v1/provision/device'
      const data = {
        deviceID: this.deviceID,
        email: user,
        nickname: this.nickname,
        config: {
          temperature_alert: Number(this.temperatureAlert),
          temperature_warning: Number(this.temperatureWarning),
          temperature_target: Number(this.temperatureTarget),
          telemetry_period: Number(this.period)
        }
      }
      const params = {
        body: JSON.stringify(data),
        headers: {
          authorization: token!
        },
        method: 'POST'
      }

      var resp : Response = await fetch(url, params)

      if (resp.status !== 201) {
        this.errorsnackbar = true
      } else {
        this.snackbar = true
        this.$emit('deviceCreated')
      }

      this.loading = false
      this.dialog = false
    },
    getSelect (a: any) {
      this.period = a
    }
  }
})
