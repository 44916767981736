


































































import Vue from 'vue'
import { auth, firestore } from '../main'
import { collection, deleteDoc, getDocs, query, where } from 'firebase/firestore'

export default Vue.extend({
  name: 'notification-button',
  data: () => ({
    isLoggedIn: false,
    menu: false,
    message: false,
    notifications: 0,
    notification_list: []
  }),
  mounted () {
    this.getUnreadMessages(this)
  },
  methods: {
    getUnreadMessages: async function (context: any) {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }

      const telemetryref = collection(firestore, 'users', user, 'notifications')
      const notifications = await getDocs(telemetryref)

      notifications.forEach(async (element) => {
        const incomingNotification = {
          title: ''
        }
        incomingNotification.title = element.get('title')
        context.notification_list.push(incomingNotification)
      })

      this.notifications = notifications.size
    },
    deleteAllMessages: async function () {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }

      const telemetryref = collection(firestore, 'users', user, 'notifications')
      const notifications = await getDocs(telemetryref)

      notifications.forEach(async (element) => {
        await deleteDoc(element.ref)
      })

      this.notifications = notifications.size
    }
  }

})
