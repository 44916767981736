










































import Vue from 'vue'
import { auth } from './main'
import NotificationButton from './components/NotificationButton.vue'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

export default Vue.extend({
  name: 'App',

  components: {
    NotificationButton
  },
  data: () => ({
    isLoggedIn: false,
    drawer: false,
    // group: null,
    notifications: 0
  }),
  methods: {
    logout: function () {
      auth.signOut().then(() => {
        console.log('signout completed')
        this.$router.push('/login')
      })
    }
    // getMessagingToken: async () => {
    //   const messaging = getMessaging()
    //   const token = await getToken(messaging, { vapidKey: 'BGFYITqeOoj8q-wunDZjlP-n2GPuYFf4QIf8OMQdEdqB1Nd3btnDeVGVYO9V6XKJT2uWJhUHyyU1dzATqQrKR5s' })
    //   console.log(token)
    //   onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload)
    //   })
    // }
  },

  mounted () {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.isLoggedIn = true
      } else {
        this.isLoggedIn = false
      }
    })

    // this.getMessagingToken()
    // const messaging = getMessaging()
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload)
    // })
  }
})
